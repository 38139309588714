/*=========
Font load
===========*/
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,531;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,531;1,600;1,700;1,800;1,900&display=swap');
$body-font: 'Jost', sans-serif;
$heading: 'Jost', sans-serif;

/*=========
Color Code
===========*/
$bage-bg: #f9f9f9;
$body-color: #2f3146;
$base-color: #00458d;
$color-primary: #000;
$color-secondary: #fff;
$color-white: #fff;
$color-blackis: #1a1a1a;
$btn-circle-decor-dimensions: 50px;
